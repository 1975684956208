var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "opportunity-job-detail",
    },
    [
      _c("div", { staticClass: "opportunity-job-detail-header" }, [
        _c("div", { staticClass: "left" }, [
          _c("h2", { staticClass: "public-job-name" }, [
            _vm._v(_vm._s(_vm.publicInfo.jobName)),
          ]),
          _c("p", { staticClass: "public-job-primary" }, [
            _c(
              "span",
              { attrs: { title: "月薪" } },
              [
                _c("font-icon", { attrs: { href: "#icon-rmb-circle-o" } }),
                _c("em", [
                  _vm._v(
                    _vm._s(_vm.publicInfo.minSalary) +
                      "K-" +
                      _vm._s(_vm.publicInfo.maxSalary) +
                      "K"
                  ),
                ]),
              ],
              1
            ),
            _c(
              "span",
              { attrs: { title: "工作经验" } },
              [
                _c("font-icon", { attrs: { href: "#icon-clock-o" } }),
                _c("em", [_vm._v(_vm._s(_vm.publicInfo.workExperience))]),
              ],
              1
            ),
            _c(
              "span",
              { attrs: { title: "学历" } },
              [
                _c("font-icon", { attrs: { href: "#icon-article-o" } }),
                _c("em", [_vm._v(_vm._s(_vm.publicInfo.degree))]),
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "public-job-location",
                attrs: { title: "工作地区" },
              },
              [
                _c("font-icon", { attrs: { href: "#icon-company" } }),
                _c("em", { attrs: { title: _vm.publicInfo.locationsText } }, [
                  _vm._v(_vm._s(_vm.publicInfo.locationsText)),
                ]),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "right" }, [
          _c(
            "div",
            { staticClass: "option-btn" },
            [
              _vm.isCompanyOpportunityManage
                ? _c(
                    "div",
                    [
                      _vm.status == 3
                        ? [
                            _c(
                              "el-button",
                              {
                                staticClass: "btn-primary",
                                on: { click: _vm.pass },
                              },
                              [_vm._v("通过")]
                            ),
                            _c("el-button", { on: { click: _vm.cancel } }, [
                              _vm._v("不通过"),
                            ]),
                          ]
                        : _vm._e(),
                      _vm.status == 5
                        ? _c(
                            "span",
                            { staticClass: "no-pass-tip" },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@src/assets/images/program-goal-warn.png"),
                                  alt: "",
                                },
                              }),
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "bottom",
                                    width: "322",
                                    trigger: "hover",
                                    content: _vm.rejectReason,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "no-pass-reason",
                                      attrs: { slot: "reference" },
                                      slot: "reference",
                                    },
                                    [_vm._v("审核未通过，查看原因")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : [
                    _vm.userId == _vm.userInfo.id
                      ? [
                          _vm.status == 0 || _vm.status == 5 || _vm.status == 7
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "btn-primary",
                                  on: { click: _vm.edit },
                                },
                                [_vm._v("编辑")]
                              )
                            : _vm._e(),
                          _vm.status == 7
                            ? _c(
                                "el-button",
                                { on: { click: _vm.publishPublicJob } },
                                [_vm._v("发布")]
                              )
                            : _vm._e(),
                          _vm.status == 0 || _vm.status == 5
                            ? _c(
                                "el-button",
                                { on: { click: _vm.submitAudit } },
                                [_vm._v("提交审核")]
                              )
                            : _vm._e(),
                          _vm.status == 3
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "btn-primary",
                                  on: { click: _vm.cancelAudit },
                                },
                                [_vm._v("撤回审核")]
                              )
                            : _vm._e(),
                          _vm.status == 9
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "btn-primary",
                                  on: { click: _vm.cancelPublish },
                                },
                                [_vm._v("取消发布")]
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm.status == 5
                      ? _c(
                          "span",
                          { staticClass: "no-pass-tip" },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@src/assets/images/program-goal-warn.png"),
                                alt: "",
                              },
                            }),
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "bottom",
                                  width: "322",
                                  trigger: "hover",
                                  content: _vm.rejectReason,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "no-pass-reason",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [_vm._v("审核未通过，查看原因")]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
            ],
            2
          ),
        ]),
      ]),
      _c("opportunity-job-detail-content", {
        ref: "publicJobContent",
        attrs: {
          originalInfo: _vm.originalInfo,
          publicInfo: _vm.publicInfo,
          status: _vm.status,
          jobId: _vm.jobId,
        },
      }),
      _c("famous-company-dialog", {
        ref: "famousCompanyDialog",
        on: {
          editSuccess: function ($event) {
            return _vm.getPublicJobEditInfo()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }