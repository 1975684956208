<template>
    <div class="opportunity-job-detail" v-loading="loading">
        <div class="opportunity-job-detail-header">
            <div class="left">
                <h2 class="public-job-name">{{ publicInfo.jobName }}</h2>
                <p class="public-job-primary">
                    <span title="月薪">
                        <font-icon href="#icon-rmb-circle-o"></font-icon>
                        <em>{{ publicInfo.minSalary }}K-{{publicInfo.maxSalary}}K</em>
                    </span>
                    <span title="工作经验">
                        <font-icon href="#icon-clock-o"></font-icon>
                        <em>{{ publicInfo.workExperience}}</em>
                    </span>
                    <span title="学历">
                        <font-icon href="#icon-article-o"></font-icon>
                        <em>{{ publicInfo.degree }}</em>
                    </span>
                    <span title="工作地区" class="public-job-location">
                        <font-icon href="#icon-company"></font-icon>
                        <em :title="publicInfo.locationsText">{{ publicInfo.locationsText }}</em>
                    </span>
                </p>
            </div>
            <div class="right">
                <div class="option-btn">
                    <div v-if="isCompanyOpportunityManage">
                        <template  v-if="status == 3">
                            <el-button class="btn-primary"  @click="pass">通过</el-button>
                            <el-button @click="cancel">不通过</el-button>
                        </template>
                        <span class="no-pass-tip" v-if="status == 5">
                            <img
                                src="@src/assets/images/program-goal-warn.png"
                                alt=""
                            />
                            <el-popover
                                placement="bottom"
                                width="322"
                                trigger="hover"
                                :content="rejectReason"
                            >
                                <span slot="reference" class="no-pass-reason"
                                    >审核未通过，查看原因</span
                                >
                            </el-popover>
                        </span>
                    </div>
                    <template v-else>
                        <template v-if="userId == userInfo.id">
                            <el-button class="btn-primary" v-if="status == 0 || status == 5 || status == 7" @click="edit">编辑</el-button>
                            <el-button v-if="status == 7" @click="publishPublicJob">发布</el-button>
                            <el-button v-if="status == 0 || status == 5" @click="submitAudit">提交审核</el-button>
                            <el-button class="btn-primary" v-if="status == 3" @click="cancelAudit">撤回审核</el-button>
                            <el-button class="btn-primary" v-if="status == 9" @click="cancelPublish">取消发布</el-button>
                        </template>
                        <span class="no-pass-tip" v-if="status == 5">
                            <img
                                src="@src/assets/images/program-goal-warn.png"
                                alt=""
                            />
                            <el-popover
                                placement="bottom"
                                width="322"
                                trigger="hover"
                                :content="rejectReason"
                            >
                                <span slot="reference" class="no-pass-reason"
                                    >审核未通过，查看原因</span
                                >
                            </el-popover>
                        </span>
                    </template>
                </div>
            </div>
        </div>
        <opportunity-job-detail-content
            ref="publicJobContent"
            :originalInfo="originalInfo"
            :publicInfo="publicInfo"
            :status="status"
            :jobId="jobId"
        ></opportunity-job-detail-content>

        <famous-company-dialog
            ref="famousCompanyDialog"
            @editSuccess="getPublicJobEditInfo()"
        />
    </div>
</template>

<script>
import OpportunityJobDetailContent from "./layout/job-detail-content.vue";
import FamousCompanyDialog from '#/views/job-detail/component/famous-company-dialog.vue';
import { 
    workExperience as workExperienceJson, 
    degree as degreeJson 
} from '#/js/config/searchField.json';
import { getCityTextById } from '#/js/util/provinceCity.js';
import PublicJobService from '#/js/service/publicJobService.js';

export default {
    components: {
        OpportunityJobDetailContent,
        FamousCompanyDialog,
    },
    data() {
        return {
            loading: false,
            jobId: '', // 原职位id
            publicJobId: '',
            status: '', 
            rejectReason: '',
            originalInfo: {},
            publicInfo: {},
        };
    },
    filters: {
        
    },
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        userId() {
            return this.$route.query.userId;
        },
        isCFUser() {
            return this.$store.state.user.userInfo.isCFUser;
        },
        // 判断是否有审核大厂机会的权限
        isCompanyOpportunityManage () {
            return this.$store.state.user.userInfo.privilegeCodeList?.includes('CompanyOpportunityAuditSet');
        },
    },
    mounted() {
        if(!this.isCFUser) {
            shortTips("权限不足，请联系管理员");
            setTimeout(() => {
                location.href = "/Headhunting/#/";
            }, 2000);
        } 

        this.publicJobId = this.$route.params.id;
        this.jobId = this.$route.query.jobId;
        this.getPublicJobEditInfo();
    },
    methods: {
        filterDegree(val) {
            return degreeJson[String(val)];
        },
        filteWorkExperience(val) {
            return workExperienceJson[String(val)];
        },
        filteLocations(val) {
            let locationsText = [];
            val.forEach(el => {
                locationsText.push(getCityTextById(el));
            })
            return locationsText.join(',');
        },
        // 获取大厂职位详情
        getPublicJobEditInfo() {
            this.loading = true;
            PublicJobService.getPublicJobEditInfo(this.jobId)
                .then(res => {
                    // console.log(res);
                    this.originalInfo = res.originalInfo;
                    this.publicInfo = res.publicInfo;
                    
                    this.status = res.status;
                    this.rejectReason = res.rejectReason;
                    
                    this.originalInfo.salary = `${res.originalInfo.minSalary}K-${res.originalInfo.maxSalary}K`;
                    this.originalInfo.degree = this.filterDegree(this.originalInfo.minDegree);
                    this.originalInfo.workExperience = this.filteWorkExperience(this.originalInfo.minYearOfExperience);
                    this.originalInfo.locationsText = this.filteLocations(this.originalInfo.locationIds);

                    this.publicInfo.salary = `${res.publicInfo.minSalary}K-${res.publicInfo.maxSalary}K`;
                    this.publicInfo.degree = this.filterDegree(res.publicInfo.minDegree);
                    this.publicInfo.workExperience = this.filteWorkExperience(res.publicInfo.minYearOfExperience);
                    this.publicInfo.locationsText = this.filteLocations(res.publicInfo.locationIds);

                    this.loading = false;
                }).catch(err => {
                    console.log(err);
                    this.loading = false;
                }); 
        },
        // 不通过
        cancel () {
            this.$prompt('请输入不通过原因', '请输入原因', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^.{1,100}$/,
                inputErrorMessage: '不通过原因不能为空,并且字数不能超过100字'
            })
                .then(({value}) => {
                    const params = {
                        acceptable: false,
                        publicJobId: this.publicJobId,
                        rejectReason: value?.trim(),
                    };

                    PublicJobService.publicJobAudit(params)
                        .then(res => {
                            this.getPublicJobEditInfo();
                            this.dialogVisible = false;
                        });
                })
                .catch(e => {});
        },
        // 通过
        pass () {
            this.$confirm('审核通过这条发布至大厂机会中职位信息?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    const params = {
                        acceptable: true,
                        publicJobId: this.publicJobId,
                        rejectReason: '',
                    };

                    PublicJobService.publicJobAudit(params)
                        .then(res => {
                            this.getPublicJobEditInfo();
                            this.dialogVisible = false;
                        });
                })
                .catch(() => { });
        },

        // 编辑
        edit(){
            this.$refs['famousCompanyDialog'].show(this.originalInfo, this.publicInfo, this.publicJobId);
        },
        // 撤回审核
        cancelAudit() {
            this.loading = true;
            PublicJobService.cancelAudit(this.publicJobId)
                .then(res => {
                    shortTips('撤回审核成功');
                    this.loading = false;
                    this.getPublicJobEditInfo();
                }).catch(err => {
                    console.log(err);
                    this.loading = false;
                });
        },
        // 取消发布
        cancelPublish() {
            this.loading = true;
            PublicJobService.cancelPublish(this.publicJobId)
                .then(res => {
                    shortTips('取消发布成功');
                    this.loading = false;
                    this.getPublicJobEditInfo();
                }).catch(err => {
                    console.log(err);
                    this.loading = false;
                });
        },
        // 提交审核
        submitAudit() {
            this.loading = true;
            PublicJobService.submitAudit(this.publicJobId)
                .then(res => {
                    shortTips('提交审核成功');
                    this.loading = false;
                    this.getPublicJobEditInfo();
                }).catch(err => {
                    console.log(err);
                    this.loading = false;
                });
        },
        // 发布
        publishPublicJob() {
            this.loading = true;
            PublicJobService.publishPublicJob(this.publicJobId)
                .then(res => {
                    shortTips('发布成功');
                    this.loading = false;
                    this.getPublicJobEditInfo();
                }).catch(err => {
                    console.log(err);
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.opportunity-job-detail {
    height: 100%;
    overflow-y: auto;
    padding: 20px;

    &-header {
        width: 1200px;
        height: 170px;
        margin: 0 auto 20px;
        padding: 0 40px;
        display: flex;
        align-items: center;
        background-color: #fff;
        border-radius: 8px;
        .left {
            flex: 1;
            .public-job-name {
                color: #333333;
                font-size: 24px;
            }
            .public-job-primary {
                width: 98%;
                font-size: 16px;
                line-height: 20px;
                display: flex;
                > span {
                    margin-left: 10px;
                    white-space: nowrap;
                    &:first-child,
                    &.convention-icon {
                        margin-left: 0;
                    }
                }
                .public-job-location {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 290px;
                }
            }
        }
        .right {
            flex: 1;
            text-align: center;
            .option-btn {
                .el-button {
                    width: 124px;
                    height: 46px;
                    font-size: 16px;
                    border-radius: 4px;
                }
                .btn-primary {
                    background-color: #38BC9D;
                    color: #fff;
                }
                .no-pass-tip {
                    margin-left: 20px;
                    img {
                        margin-right: 5px;
                    }
                    .no-pass-reason {
                        cursor: pointer;
                        color: #ff493c;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
</style>