<template>
    <div class="collect-table">
        <el-table
            v-loading="loading"
            :data="collectData"
            border
            style="width: 100%"
            tooltip-effect="light"
        >
            <el-table-column
                prop="realName"
                align="left"
                width="300"
                label="姓名"
                :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
                prop="mobile"
                align="left"
                width="200"
                label="手机号"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.mobile ? scope.row.mobile : '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="gender"
                align="left"
                width="130"
                label="性别"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.gender == 0 ? '男' : '女' }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="location"
                align="left"
                min-width="200"
                :show-overflow-tooltip="true"
                label="所在城市"
            >
                <template slot-scope="scope">
                    {{ getLocations(scope.row.location) }}
                </template>
            </el-table-column>
            <el-table-column
                prop="birthday"
                align="left"
                width="130"
                label="年龄"
            >
                <template slot-scope="scope">
                    <span>{{ birthdayToAge(scope.row.birthday) ? birthdayToAge(scope.row.birthday) + '岁' : '' }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="email"
                align="left"
                min-width="250"
                :show-overflow-tooltip="true"
                label="邮箱"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.email ? scope.row.email : '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="wechat"
                align="left"
                min-width="250"
                :show-overflow-tooltip="true"
                label="微信"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.wechat ? scope.row.wechat : '-' }}</span>
                </template>
            </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-pagination
            :current-page="pager.current"
            :page-sizes="[10, 30, 50]"
            :page-size="pager.take"
            class="deliver-list-pagination"
            layout="prev, pager, next, slot, total, sizes"
            :total="pager.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            v-if="pager.total > pager.take"
        >
            <span class="pagination-text">
                <span>
                    前往
                    <el-input
                        v-model="pagerJump"
                        @keyup.enter.native="handlePagerJump(pagerJump)"
                    ></el-input>
                    页
                </span>
                <span @click="handlePagerJump(pagerJump)"> 跳转 </span>
            </span>
        </el-pagination>
    </div>
</template>

<script>
import { getAgeFromBirthday } from '#/js/util/tools.js';
import { getCityTextById } from '#/js/util/provinceCity.js';


export default {
    props: {
        collectData: Array,
        loading: Boolean,
    },
    data() {
        return {
            pager: {
                current: 1,
                take: 10,
                total: 0,
            },
            pagerJump: 0,
        };
    },

    mounted() {},

    methods: {
        birthdayToAge(birthday) {
            return getAgeFromBirthday(birthday);
        },
        getLocations(sLocation) {
            if (!sLocation) return '';
            const aLocations = sLocation.split('.');
            let aLocationsText = [];

            aLocations.forEach(item => {
                const _text = getCityTextById(item);
                if (_text) aLocationsText.push(_text);
            });
            return aLocationsText.join(',');
        },

        // 分页
        handleSizeChange(val) {
            this.pager.take = val;
            this.pager.current = 1;
            this.$emit('update-list', this.pager);
        },
        handleCurrentChange(current) {
            this.pager.current = current;
            this.$emit('update-list', this.pager);
        },
        handlePagerJump(pagerJump) {
            pagerJump = Number.parseInt(pagerJump);
            if (
                pagerJump > 0 &&
                pagerJump <= Math.ceil(this.total / this.pager.take)
            ) {
                this.handleCurrentChange(pagerJump);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.collect-table {
    /deep/ .el-table {
        thead tr th {
            background-color: #e8e8e8;
            .cell {
                color: #666;
                font-weight: bold;
                padding: 0 20px;
            }
        }
        td .cell {
            padding: 0 20px;
        }
        th,
        td {
            padding: 8px 0;
        }
    }

    .item-option {
        span {
            cursor: pointer;
            display: inline-block;
            color: #38bc9d;
            font-size: 14px;
        }
    }

    /deep/ .deliver-list-pagination.el-pagination {
        margin: 40px 0 20px 0;
        padding: 0;
        font-weight: normal;
        .btn-next,
        .btn-prev {
            height: 38px;
            border: 1px solid;
        }
        .btn-prev {
            border-right: none;
            border-radius: 4px 0 0 4px;
        }
        .btn-next {
            border-left: none;
            border-radius: 0 4px 4px 0;
        }
        .el-pagination__sizes .el-input {
            width: 87px;
            .el-input__inner {
                color: #666;
                .el-select__caret {
                    color: #999;
                }
            }
        }
        .el-pager {
            border-top: 1px solid;
            border-bottom: 1px solid;
            li {
                min-width: 36px;
                height: 36px;
                line-height: 36px;
                font-size: 14px;
            }
        }
        .btn-next,
        .btn-prev,
        .el-pager {
            border-color: #dddddd;
        }
        .el-input__inner,
        .el-pagination__total,
        .el-pagination__jump {
            height: 38px;
            line-height: 38px;
            font-size: 14px;
        }
        .pagination-text {
            color: #999;
            span {
                height: 38px;
                line-height: 38px;
                font-size: 14px;
                .el-input {
                    width: 48px;
                    margin: 0 5px;
                    &__inner {
                        height: 38px;
                        line-height: 38px;
                        padding: 0 5px;
                    }
                }
                &:nth-of-type(2) {
                    margin-left: 5px;
                    color: $primary;
                    cursor: pointer;
                }
            }
        }
        .el-pagination__total,
        .el-pagination__sizes,
        .pagination-text {
            float: right;
        }
    }
}
</style>