<template>
    <div class="public-job-detail">
        <el-tabs
            class="public-job-detail-tabs"
            v-model="publicJobActiveTabName"
           @tab-click="handleClick"
        >
             <el-tab-pane 
                :name="item.name" 
                v-for="(item, index) in tabList" 
                :key="'tab_' + index"
            >
                <span slot="label" class="tab-title">{{ item.label }}</span>
                <div class="main" v-loading="loading">
                    <!-- 职位信息 -->
                    <div class="main-info" v-if="item.name == 'publicJobIntro'">
                        <div class="form-wrap">
                            <div class="top-title">
                                <h1>原职位信息<a target="_blank" :href="`/#/Job/${jobId}`">查看原职位</a></h1>
                            </div>
                            <el-form :model="originalInfo" disabled label-width="80px">
                                <el-form-item label="职位名称">
                                    <el-input v-model="originalInfo.jobName"></el-input>
                                </el-form-item>
                                <el-form-item label="公司名称">
                                    <el-input
                                        v-model="originalInfo.customerName"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="工作地点">
                                    <el-input
                                        v-model="originalInfo.locationsText"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="经验要求">
                                    <el-input
                                        v-model="originalInfo.workExperience"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="学历要求">
                                    <el-input v-model="originalInfo.degree"></el-input>
                                </el-form-item>
                                <el-form-item label="薪资范围">
                                    <el-input v-model="originalInfo.salary"></el-input>
                                </el-form-item>
                                <el-form-item label="职位描述">
                                    <el-input
                                        :rows="4"
                                        type="textarea"
                                        v-model="originalInfo.description"
                                    ></el-input>
                                </el-form-item>
                            </el-form>
                        </div>

                        <div class="form-wrap">
                            <div class="top-title">
                                <h1>发布到大厂机会职位信息</h1>
                            </div>
                            <el-form
                                :model="publicInfo"
                                disabled
                                label-width="80px"
                                ref="publishForm"
                            >
                                <el-form-item label="职位名称" prop="jobName">
                                    <el-input
                                        maxlength="128"
                                        v-model="publicInfo.jobName"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item
                                    label="公司名称"
                                    prop="customerName"
                                >
                                    <el-input
                                        maxlength="128"
                                        v-model="publicInfo.customerName"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="工作地点">
                                    <el-input
                                        v-model="publicInfo.locationsText"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="经验要求">
                                    <el-input
                                        v-model="publicInfo.workExperience"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="学历要求">
                                    <el-input
                                        v-model="publicInfo.degree"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="薪资范围">
                                    <el-input v-model="publicInfo.salary"></el-input>
                                </el-form-item>
                                <el-form-item
                                    label="职位描述"
                                    prop="description"
                                >
                                    <el-input
                                        :rows="4"
                                        maxlength="8000"
                                        type="textarea"
                                        v-model="publicInfo.description"
                                    ></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>

                    <!-- 投递列表 -->
                    <deliver-table 
                        ref="deliverTable" 
                        v-if="item.name == 'publicJobDeliver'" 
                        :deliverData="deliverData"
                        @update-list="(val) => getDeliverList(val)"
                    ></deliver-table>

                    <!-- 收藏列表 -->
                    <collect-table 
                        ref="collectTable" 
                        v-if="item.name == 'publicJobCollect'" 
                        :collectData="collectData"
                        @update-list="(val) => getCollectList(val)"
                    ></collect-table>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import DeliverTable from "../component/deliver-table.vue";
import CollectTable from "../component/collect-table.vue";
import PublicJobService from '#/js/service/publicJobService.js';

export default {
    props: {
        status: Number|String,
        jobId: String,
        originalInfo: Object,
        publicInfo: Object,
    },
    components: {
        DeliverTable,
        CollectTable
    },
    data() {
        return {
            publicJobActiveTabName: "publicJobIntro",
            tabList: [
                {
                    name: 'publicJobIntro',
                    label: '职位'
                },
                {
                    name: 'publicJobDeliver',
                    label: '投递'
                },
                {
                    name: 'publicJobCollect',
                    label: '收藏'
                },
            ],

            loading: false,
            deliverData: [], // 投递列表
            collectData: [], // 收藏列表
        };
    },
    mounted() {
        this.getDeliverList();
        this.getCollectList();
    },
    methods: {
        handleClick(tab, event) {
            // console.log(tab, event);
            this.publicJobActiveTabName = tab.name;
        },

        // 获取投递列表
        getDeliverList(pager) {
            console.log('加载投递列表----------');
            console.log(this.$route);
            this.loading = true;
            if(!pager) {
                pager = {
                    start: 0,
                    take: 10
                }
            }
            let params = Object.assign({}, { 'PublicJobId': this.$route.params.id }, pager);
            PublicJobService.getPublicJobDeliverList(params)
                .then(res => {
                    this.loading = false;
                    this.deliverData = res.list;
                    this.$set(this.$refs.deliverTable[0].pager, 'total', res.total);
                    document.querySelector('.opportunity-job-detail').scrollTop = document.querySelector('.opportunity-job-detail').offsetTop;
                }).catch(err => {
                    this.loading = false;
                    console.log(err);
                });
            this.loading = false;
        },
        // 获取收藏列表
        getCollectList(pager) {
            // console.log('加载收藏列表----------');
            // console.log(this.$route);
            this.loading = true;
            if(!pager) {
                pager = {
                    start: 0,
                    take: 10
                }
            }
            let params = Object.assign({}, { 'PublicJobId': this.$route.params.id }, pager);
            PublicJobService.getPublicJobCollectList(params)
                .then(res => {
                    this.loading = false;
                    this.collectData = res.list;
                    this.$set(this.$refs.collectTable[0].pager, 'total', res.total);
                    document.querySelector('.opportunity-job-detail').scrollTop = document.querySelector('.opportunity-job-detail').offsetTop;
                }).catch(err => {
                    this.loading = false;
                    console.log(err);
                });
            this.loading = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.public-job-detail {
    width: 1200px;
    margin: 0 auto;
    min-height: calc(100vh - 210px);
    background-color: #fff;
    border-radius: 8px;

    /deep/ .public-job-detail-tabs.el-tabs {
        .el-tabs__nav-scroll {
            padding: 0 28px;
            &::after {
                height: 1px;
            }
        }
        .el-tabs__active-bar {
            width: 34px !important;
            height: 4px;
        }
        .el-tabs__item {
            padding: 0 30px;
            height: 70px;
            line-height: 70px;
            font-size: 17px;
            color: #999999;
            position: relative;
            &.is-active {
                color: $primary;
            }
            &:hover {
                color: $primary;
            }
        }
        .el-tabs__header {
            margin-bottom: 0;
        }
        .el-tabs__content {
            position: relative;
            overflow: unset;
        }
    }

    .main {
        padding: 20px;
        &-info {
            display: flex;
            width: 840px;
            .form-wrap {
                flex: 1;
                box-sizing: border-box;
                padding: 25px 20px;
                &:first-child {
                    border-right: 1px solid #eee;
                }

                .top-title {
                    height: 40px;
                    margin-bottom: 16px;
                    > h1 {
                        &::before {
                            display: inline-block;
                            background: $primary;
                            content: "";
                            width: 4px;
                            height: 20px;
                            margin-right: 13px;
                            vertical-align: middle;
                        }
                        color: #333333;
                        font-size: 16px;
                        margin: 0;

                        > a {
                            display: inline-block;
                            color: #38bc9d;
                            font-size: 14px;
                            padding-left: 18px;
                        }
                    }
                }

                .salary-wrap {
                    display: flex;
                    justify-content: space-between;
                    > div {
                        flex: 1;
                        max-width: 48%;
                    }
                }
            }
        }
    }
}
</style>