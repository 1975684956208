var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "public-job-detail" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "public-job-detail-tabs",
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.publicJobActiveTabName,
            callback: function ($$v) {
              _vm.publicJobActiveTabName = $$v
            },
            expression: "publicJobActiveTabName",
          },
        },
        _vm._l(_vm.tabList, function (item, index) {
          return _c(
            "el-tab-pane",
            { key: "tab_" + index, attrs: { name: item.name } },
            [
              _c(
                "span",
                {
                  staticClass: "tab-title",
                  attrs: { slot: "label" },
                  slot: "label",
                },
                [_vm._v(_vm._s(item.label))]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "main",
                },
                [
                  item.name == "publicJobIntro"
                    ? _c("div", { staticClass: "main-info" }, [
                        _c(
                          "div",
                          { staticClass: "form-wrap" },
                          [
                            _c("div", { staticClass: "top-title" }, [
                              _c("h1", [
                                _vm._v("原职位信息"),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      target: "_blank",
                                      href: `/#/Job/${_vm.jobId}`,
                                    },
                                  },
                                  [_vm._v("查看原职位")]
                                ),
                              ]),
                            ]),
                            _c(
                              "el-form",
                              {
                                attrs: {
                                  model: _vm.originalInfo,
                                  disabled: "",
                                  "label-width": "80px",
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "职位名称" } },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.originalInfo.jobName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.originalInfo,
                                            "jobName",
                                            $$v
                                          )
                                        },
                                        expression: "originalInfo.jobName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "公司名称" } },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.originalInfo.customerName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.originalInfo,
                                            "customerName",
                                            $$v
                                          )
                                        },
                                        expression: "originalInfo.customerName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "工作地点" } },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.originalInfo.locationsText,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.originalInfo,
                                            "locationsText",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "originalInfo.locationsText",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "经验要求" } },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.originalInfo.workExperience,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.originalInfo,
                                            "workExperience",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "originalInfo.workExperience",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "学历要求" } },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.originalInfo.degree,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.originalInfo,
                                            "degree",
                                            $$v
                                          )
                                        },
                                        expression: "originalInfo.degree",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "薪资范围" } },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.originalInfo.salary,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.originalInfo,
                                            "salary",
                                            $$v
                                          )
                                        },
                                        expression: "originalInfo.salary",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "职位描述" } },
                                  [
                                    _c("el-input", {
                                      attrs: { rows: 4, type: "textarea" },
                                      model: {
                                        value: _vm.originalInfo.description,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.originalInfo,
                                            "description",
                                            $$v
                                          )
                                        },
                                        expression: "originalInfo.description",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "form-wrap" },
                          [
                            _c("div", { staticClass: "top-title" }, [
                              _c("h1", [_vm._v("发布到大厂机会职位信息")]),
                            ]),
                            _c(
                              "el-form",
                              {
                                ref: "publishForm",
                                refInFor: true,
                                attrs: {
                                  model: _vm.publicInfo,
                                  disabled: "",
                                  "label-width": "80px",
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "职位名称",
                                      prop: "jobName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { maxlength: "128" },
                                      model: {
                                        value: _vm.publicInfo.jobName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.publicInfo,
                                            "jobName",
                                            $$v
                                          )
                                        },
                                        expression: "publicInfo.jobName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "公司名称",
                                      prop: "customerName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { maxlength: "128" },
                                      model: {
                                        value: _vm.publicInfo.customerName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.publicInfo,
                                            "customerName",
                                            $$v
                                          )
                                        },
                                        expression: "publicInfo.customerName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "工作地点" } },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.publicInfo.locationsText,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.publicInfo,
                                            "locationsText",
                                            $$v
                                          )
                                        },
                                        expression: "publicInfo.locationsText",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "经验要求" } },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.publicInfo.workExperience,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.publicInfo,
                                            "workExperience",
                                            $$v
                                          )
                                        },
                                        expression: "publicInfo.workExperience",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "学历要求" } },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.publicInfo.degree,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.publicInfo,
                                            "degree",
                                            $$v
                                          )
                                        },
                                        expression: "publicInfo.degree",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "薪资范围" } },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.publicInfo.salary,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.publicInfo,
                                            "salary",
                                            $$v
                                          )
                                        },
                                        expression: "publicInfo.salary",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "职位描述",
                                      prop: "description",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        rows: 4,
                                        maxlength: "8000",
                                        type: "textarea",
                                      },
                                      model: {
                                        value: _vm.publicInfo.description,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.publicInfo,
                                            "description",
                                            $$v
                                          )
                                        },
                                        expression: "publicInfo.description",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  item.name == "publicJobDeliver"
                    ? _c("deliver-table", {
                        ref: "deliverTable",
                        refInFor: true,
                        attrs: { deliverData: _vm.deliverData },
                        on: { "update-list": (val) => _vm.getDeliverList(val) },
                      })
                    : _vm._e(),
                  item.name == "publicJobCollect"
                    ? _c("collect-table", {
                        ref: "collectTable",
                        refInFor: true,
                        attrs: { collectData: _vm.collectData },
                        on: { "update-list": (val) => _vm.getCollectList(val) },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }