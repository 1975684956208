var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "collect-table" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.collectData,
            border: "",
            "tooltip-effect": "light",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "realName",
              align: "left",
              width: "300",
              label: "姓名",
              "show-overflow-tooltip": true,
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "mobile",
              align: "left",
              width: "200",
              label: "手机号",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.mobile ? scope.row.mobile : "-")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "gender",
              align: "left",
              width: "130",
              label: "性别",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.gender == 0 ? "男" : "女")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "location",
              align: "left",
              "min-width": "200",
              "show-overflow-tooltip": true,
              label: "所在城市",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.getLocations(scope.row.location)) +
                        "\n            "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "birthday",
              align: "left",
              width: "130",
              label: "年龄",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.birthdayToAge(scope.row.birthday)
                            ? _vm.birthdayToAge(scope.row.birthday) + "岁"
                            : ""
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "email",
              align: "left",
              "min-width": "250",
              "show-overflow-tooltip": true,
              label: "邮箱",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.email ? scope.row.email : "-")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "wechat",
              align: "left",
              "min-width": "250",
              "show-overflow-tooltip": true,
              label: "微信",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.wechat ? scope.row.wechat : "-")),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.pager.total > _vm.pager.take
        ? _c(
            "el-pagination",
            {
              staticClass: "deliver-list-pagination",
              attrs: {
                "current-page": _vm.pager.current,
                "page-sizes": [10, 30, 50],
                "page-size": _vm.pager.take,
                layout: "prev, pager, next, slot, total, sizes",
                total: _vm.pager.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            },
            [
              _c("span", { staticClass: "pagination-text" }, [
                _c(
                  "span",
                  [
                    _vm._v("\n                前往\n                "),
                    _c("el-input", {
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handlePagerJump(_vm.pagerJump)
                        },
                      },
                      model: {
                        value: _vm.pagerJump,
                        callback: function ($$v) {
                          _vm.pagerJump = $$v
                        },
                        expression: "pagerJump",
                      },
                    }),
                    _vm._v("\n                页\n            "),
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.handlePagerJump(_vm.pagerJump)
                      },
                    },
                  },
                  [_vm._v(" 跳转 ")]
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }