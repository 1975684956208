import { render, staticRenderFns } from "./deliver-table.vue?vue&type=template&id=764483aa&scoped=true&"
import script from "./deliver-table.vue?vue&type=script&lang=js&"
export * from "./deliver-table.vue?vue&type=script&lang=js&"
import style0 from "./deliver-table.vue?vue&type=style&index=0&id=764483aa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "764483aa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Work\\CSDN\\Code\\baza-lbd-portal-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('764483aa')) {
      api.createRecord('764483aa', component.options)
    } else {
      api.reload('764483aa', component.options)
    }
    module.hot.accept("./deliver-table.vue?vue&type=template&id=764483aa&scoped=true&", function () {
      api.rerender('764483aa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/opportunity-detail/component/deliver-table.vue"
export default component.exports